<template>
  <b-row class="col-8 mx-0 px-0">
    <b-col md="6" sm="12">
      <statistic-card-with-line-chart
        :icon="count_subscribers.icon"
        :color="count_subscribers.color"
        :statistic="count_subscribers.statistic"
        :statistic-title="count_subscribers.statistic_title"
        :chart-data="count_subscribers.series"
      />
    </b-col>
    <b-col md="6" sm="12">
      <statistic-card-with-line-chart
        :icon="count_view.icon"
        :color="count_view.color"
        :statistic="count_view.statistic"
        :statistic-title="count_view.statistic_title"
        :chart-data="count_view.series"
      />
    </b-col>
    <b-col md="6" sm="12">
      <statistic-card-with-line-chart
        :icon="count_watchTime.icon"
        :color="count_watchTime.color"
        :statistic="count_watchTime.statistic"
        :statistic-title="count_watchTime.statistic_title"
        :chart-data="count_watchTime.series"
      />
    </b-col>
    <b-col md="6" sm="12">
      <statistic-card-with-line-chart
        :icon="count_upload.icon"
        :color="count_upload.color"
        :statistic="count_upload.statistic"
        :statistic-title="count_upload.statistic_title"
        :chart-data="count_upload.series"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";
import StatisticCardWithLineChart from "./charts/StatisticCardWithLineChart.vue";

export default {
  name: "channel-status",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    StatisticCardWithLineChart,
  },
  data() {
    return {
      count_subscribers: {
        icon: "UserIcon",
        statistic: "",
        color: "danger",
        statistic_title: "",
        series: [
          {
            name: "",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
      count_view: {
        icon: "GlobeIcon",
        statistic: "",
        color: "primary",
        statistic_title: "",
        series: [
          {
            name: "",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
      count_watchTime: {
        icon: "ClockIcon",
        statistic: "",
        color: "warning",
        statistic_title: "",
        series: [
          {
            name: "",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
      count_upload: {
        icon: "UploadCloudIcon",
        statistic: "",
        color: "success",
        statistic_title: "",
        series: [
          {
            name: "",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };
  },
  created() {
    this.getChannelStatus();
  },
  methods: {
    getChannelStatus() {
      this.$http
        .get(
          `/api/tools/assistant/channel-status?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.count_subscribers.statistic = response.data.count_subscribers;
          this.count_subscribers.statistic_title = this.$t(
            "Pages.Assistant.Title.Subscriber"
          );
          this.count_subscribers.series.name = this.$t(
            "Pages.Assistant.Title.Subscriber"
          );
          this.count_subscribers.series[0].data =
            response.data.subscribers_statistic;

          this.count_view.statistic = response.data.count_view;
          this.count_view.statistic_title = this.$t(
            "Pages.Assistant.Title.View"
          );
          this.count_view.series.name = this.$t("Pages.Assistant.Title.View");
          this.count_view.series[0].data = response.data.views_statistic;

          this.count_watchTime.statistic = response.data.count_watchTime;
          this.count_watchTime.statistic_title = this.$t(
            "Pages.Assistant.Title.watch_time"
          );
          this.count_watchTime.series.name = this.$t(
            "Pages.Assistant.Title.watch_time"
          );
          this.count_watchTime.series[0].data =
            response.data.watchTime_statistic;

          this.count_upload.statistic = response.data.count_upload;
          this.count_upload.statistic_title = this.$t(
            "Pages.Assistant.Title.Upload"
          );
          this.count_upload.series.name = this.$t(
            "Pages.Assistant.Title.Upload"
          );
          this.count_upload.series[0].data = response.data.upload_statistic;
        });
    },
  },
};
</script>

<style></style>
