<template>
  <b-col cols="12">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.ch_busi_email") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <b-row class="d-flex justify-content-center">
            <b-col md="4">
              <b-form-group>
                <b-form-input
                  class="text-center"
                  :value="infromationChannel.business_email"
                  readonly
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              v-if="infromationChannel.business_email"
              :title="
                $t(`Pages.Assistant.Title.set_busi_email`)
              "
              class="border my-1 text-success"
            >
              {{ $t(`Pages.Assistant.Title.set_busi_email`) }}
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="
                $t(`Pages.Assistant.Title.bad_set_busi_email`)
              "
              class="border my-1 text-danger"
            >
              {{
                $t(`Pages.Assistant.Title.bad_set_busi_email`)
              }}
            </app-collapse-item>
            <app-collapse-item
              :title="
                $t('Pages.Assistant.Title.what_busi_email')
              "
              class="border my-1"
            >
              {{
                $t(
                  "Pages.Assistant.Title.what_busi_email_desc"
                )
              }}
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.tips_best')"
              class="border my-1"
            >
              <ul>
                <li>
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.email_tricks_title1"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.email_tricks_desc1"
                    )
                  }}
                </p>
                <li>
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.email_tricks_title2"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.email_tricks_desc2"
                    )
                  }}
                </p>
              </ul>
            </app-collapse-item>
            <app-collapse-item
              :title="
                $t(
                  'Pages.Assistant.Title.edit_busi_email'
                )
              "
              class="border my-1"
            >
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/business-email.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "channel-email",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  props: ["infromationChannel"],
};
</script>

<style></style>
