<template>
  <b-col md="6" class="analytics-vue">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.ch_profile") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.ch_profile_img')"
              class="border my-1 collpase-item"
            >
              <hr />
              <div class="demo-inline-spacing">
                <div class="d-flex flex-column align-items-center">
                  <b-avatar
                    size="80px"
                    :src="infromationChannel.avatar"
                    square
                  />
                  <p class="pt-1 pb-0 mb-0">
                    {{ $t("Pages.Assistant.Title.size_sq") }}
                  </p>
                  <p>80x80 pixels</p>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="80px" :src="infromationChannel.avatar" />
                  <p class="pt-1 pb-0 mb-0">
                    {{ $t("Pages.Assistant.Title.size_circle") }}
                  </p>
                  <p>80x80 pixels</p>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="40px" :src="infromationChannel.avatar" />
                  <p class="pt-1 pb-0 mb-0">
                    {{ $t("Pages.Assistant.Title.comment_size_circle") }}
                  </p>
                  <p>40x40 pixels</p>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="20px" :src="infromationChannel.avatar" />
                  <p class="pt-1 pb-0 mb-0">
                    {{ $t("Pages.Assistant.Title.comment_size_circle") }}
                  </p>
                  <p>20x20 pixels</p>
                </div>
              </div>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.what_ch_name')"
              class="border my-1 collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.what_ch_profile_desc"
                  )
                }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.tips_best')"
              class="border my-1 collpase-item"
            >
              <hr />
              <ul>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title1")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc1"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title2")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc2"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title3")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc3"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title4")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc4"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title5")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc5"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title6")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc6"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.profile_tricks_title7")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.profile_tricks_desc7"
                    )
                  }}
                </p>
              </ul>
            </app-collapse-item>
            <app-collapse-item
              :title="
                $t('Pages.Assistant.Title.edit_ch_profile')
              "
              class="border my-1 collpase-item"
            >
              <hr />
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/profile.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "channel-profile",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  props: ["infromationChannel"],
};
</script>

<style></style>
