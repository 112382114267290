<template>
  <b-col md="6" class="analytics-vue">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.channel_name") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              v-if="infromationChannel.nickname.length < 30"
              :title="$t('Pages.Assistant.Title.ch_name_good')"
              class="border my-1 text-success collpase-item"
            >
              <hr />
              <p class="text-color mb-1">
             {{ $t('Pages.Assistant.Title.your_ch_name') }}: {{ infromationChannel.nickname }}
              </p>
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.ch_name_good_desc") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="$t('Pages.Assistant.Title.ch_name_bad')"
              class="border my-1 text-danger collpase-item"
            >
              <hr />
              <p class="text-color mb-1">
                {{ $t('Pages.Assistant.Title.your_ch_name') }}: {{ infromationChannel.nickname }}
              </p>
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.ch_name_good_desc") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.what_ch_name')"
              class="border my-1 collpase-item"
            >
              <hr />
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.what_ch_name_desc") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.tips_best')"
              class="border my-1 collpase-item"
            >
              <hr />
              <ul>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{ $t("Pages.Assistant.Title.trailer_tricks_title1") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.trailer_tricks_desc1") }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{ $t("Pages.Assistant.Title.trailer_tricks_title2") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.trailer_tricks_desc2") }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{ $t("Pages.Assistant.Title.trailer_tricks_title3") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.trailer_tricks_desc3") }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{ $t("Pages.Assistant.Title.trailer_tricks_title4") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.trailer_tricks_desc4") }}
                </p>
              </ul>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.edit_ch_name')"
              class="border my-1 collpase-item"
            >
              <hr />
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/channel-name.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "channel-name",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  props: ["infromationChannel"],
};
</script>

<style></style>
