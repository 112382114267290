<template>
  <b-col md="6" class="analytics-vue">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.ch_playlists") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              :title="
                $t(
                  'Pages.Assistant.Title.fewer_than_2'
                )
              "
              class="border my-1 text-danger collpase-item"
            >
              <div class="d-flex justify-content-start">
                <div
                  class="px-1 py-1"
                  v-for="play in playlists_less_than_two"
                  :key="play.id"
                >
                  <b-media left-align class="text-left mb-3">
                    <template #aside>
                      <b-avatar size="80px" :src="play.thumbnail" square />
                    </template>
                    <h4 class="media-heading" v-if="play.title">
                      {{ play.title }}
                    </h4>
                    <b-card-text>
                      {{ play.published_at }}
                    </b-card-text>
                    <b-card-text>
                      {{
                        $t("Pages.Assistant.Title.count_vid") +
                        ": " +
                        play.item_count
                      }}
                    </b-card-text>
                  </b-media>
                </div>
              </div>
            </app-collapse-item>
            <app-collapse-item
              v-if="average >= 15"
              :title="
                $t(
                  'Pages.Assistant.Title.average_num'
                )
              "
              class="border my-1 text-success collpase-item"
            >
              <hr />
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.average_num_desc1") + " "+ average +" "+ $t("Pages.Assistant.Title.average_num_desc2") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="
                $t(
                  'Pages.Assistant.Title.average_num'
                )
              "
              class="border my-1 text-danger collpase-item"
            >
              <hr />
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.average_num_desc1") + " "+ average +" "+ $t("Pages.Assistant.Title.average_num_desc2") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              v-if="average_item >= 2"
              :title="
                $t(
                  'Pages.Assistant.Title.average_num_each'
                )
              "
              class="border my-1 text-success collpase-item"
            >
              <hr />
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.average_num_each_desc1") + " "
                                    + average_item + " " + $t("Pages.Assistant.Title.average_num_each_desc2") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="
                $t(
                  'Pages.Assistant.Title.average_num_each'
                )
              "
              class="border my-1 text-danger collpase-item"
            >
              <hr />
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.average_num_each_desc1") + " "
                                    + average_item + " " + $t("Pages.Assistant.Title.average_num_each_desc2") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="
                $t('Pages.Assistant.Title.y_ch_has') +
                ' ' +
                playlists.length +
                ' ' +
                $t('Pages.Assistant.Title.public_playlist')
              "
              class="border my-1 collpase-item"
            >
              <hr />
              <div class="special-area">
                <div
                  v-for="playlist in playlists"
                  :key="playlist.id"
                  class="special-radius special-card border-btm"
                >
                  <b-media left-align class="special-card-v2">
                    <template #aside>
                      <b-avatar size="80px" :src="playlist.thumbnail" square />
                    </template>
                    <h5 class="media-heading" v-if="playlist.title">
                      {{ playlist.title }}
                    </h5>
                    <b-card-text class="mb-0">
                      {{ playlist.published_at }}
                    </b-card-text>
                    <b-card-text>
                      {{
                        $t("Pages.Assistant.Title.count_vid") +
                        ": " +
                        playlist.item_count
                      }}
                    </b-card-text>
                  </b-media>
                </div>
              </div>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.what_playlist')"
              class="border my-1 collpase-item"
            >
              <hr />
              <p class="text-color">
                {{ $t("Pages.Assistant.Title.what_playlist_desc") }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.how_create_playlist')"
              class="border my-1 collpase-item"
            >
              <hr />
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/playlists.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import MyDate from "@core/components/date/MyDate.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "channel-playlists",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  data() {
    return {
      MyDate,
      playlists: [],
      playlists_less_than_two: [],
      count_video: 0,
      average: 0,
      average_item: 0,
    };
  },
  created() {
    this.getChannelPlaylists();
  },
  methods: {
    getChannelPlaylists() {
      this.$http
        .get(
          `/api/tools/assistant/channel-playlists?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.playlists = response.data.playlists;
          this.playlists_less_than_two = response.data.playlists_less_than_two;
          this.count_video = response.data.count_video;
          this.average = Math.round(this.playlists.length / this.count_video);
          this.average_item = response.data.average_item;
        });
    },
  },
};
</script>

<style></style>
<style scoped>
.special-card {
  width: 100%;
}

.special-card-v2 {
  justify-content: center !important;
  align-items: center !important;
  gap: 15px;
  padding-bottom: 12px;
}

.special-card-v2 .media-aside {
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.special-card-v2 .media-body {
  width: 100%;
}

.special-card-v2 .media-body h4 {
  margin-bottom: 10px;
}

.special-area {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  height: 360px;
  overflow-y: scroll;
}
.border-btm {
  border-bottom: 1px solid #3b4253;
  border-radius: 0 !important;
}
.special-area .border-btm:last-child {
  border-bottom: none;
}
</style>
