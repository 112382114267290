<template>
  <b-col md="6" class="analytics-vue">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.ch_trailer") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              v-if="infromationChannel.unsubscribedTrailer"
              :title="
                $t(`Pages.Assistant.Title.add_good_ch`)
              "
              class="border my-1 text-success collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.add_good_ch"
                  )
                }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="
                $t(`Pages.Assistant.Title.add_bad_ch`)
              "
              class="border my-1 text-danger collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t("Pages.Assistant.Title.add_bad_ch")
                }}
              </p>
            </app-collapse-item>
            <!-- <app-collapse-item :title="$t('Pages.Assistant.Title.Your Channel Trailer')"
                            class="border my-1 collpase-item">
                            <hr>
                            <div class="w-100">
                                <h4 class="pb-1">{{ $t(`Pages.Assistant.Title.Channel Trailer For People Who Havent
                                    Subscribed`)
                                }}</h4>
                                <div class="mx-auto card border media-list d-flex flex-row justify-content-start align-items-center px-2"
                                    style="height: 210px; width: 90%" v-if="infromationChannel.unsubscribedTrailer">
                                    <img style="width: 300px; height: 150px"
                                        :src="infromationChannel.unsubscribedTrailer.thumbnail" />
                                    <div class="px-2 pt-1">
                                        <h3>{{ infromationChannel.unsubscribedTrailer.title }}</h3>
                                        <h5>{{
                                            `https://www.youtube.com/watch?v=${infromationChannel.unsubscribedTrailer.video_id}`
                                        }}
                                        </h5>
                                        <p>{{ infromationChannel.unsubscribedTrailer.description.slice(0, 130) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100">
                                <h4 class="pb-1">{{ $t('Pages.Assistant.Title.Featured Video For Returning Subscribers') }}
                                </h4>
                                <div class="mx-auto card border media-list d-flex flex-row justify-content-start align-items-center px-2"
                                    style="height: 210px; width: 90%" v-if="infromationChannel.unsubscribedTrailer">
                                    <img style="width: 300px; height: 150px"
                                        :src="infromationChannel.unsubscribedTrailer.thumbnail" />
                                    <div class="px-2 pt-1">
                                        <h3>{{ infromationChannel.unsubscribedTrailer.title }}</h3>
                                        <h5>{{
                                            `https://www.youtube.com/watch?v=${infromationChannel.unsubscribedTrailer.video_id}`
                                        }}
                                        </h5>
                                        <p>{{ infromationChannel.unsubscribedTrailer.description.slice(0, 130) }}</p>
                                    </div>
                                </div>
                            </div>
                        </app-collapse-item> -->
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.what_ch_trailer')"
              class="border my-1 collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.what_ch_trailer_desc"
                  )
                }}
              </p>
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.what_ch_trailer_desc2"
                  )
                }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.tips_best')"
              class="border my-1 collpase-item"
            >
              <hr />
              <ul>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.ch_name_tricks_title1")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.ch_name_tricks_desc1"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t("Pages.Assistant.Title.ch_name_tricks_title2")
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.ch_name_tricks_desc2"
                    )
                  }}
                </p>
              </ul>
            </app-collapse-item>
            <app-collapse-item
              :title="
                $t('Pages.Assistant.Title.how_edit_trailer')
              "
              class="border my-1 collpase-item"
            >
              <hr />
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/trailer.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "channel-trailer",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  props: ["infromationChannel"],
};
</script>

<style></style>
