<template>
  <b-col cols="12">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.ch_social") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
        v-if="links"
      >
        <div class="w-100 border py-1 px-1">
          <div v-for="(link, index) in links" :key="index">
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group
                  :label="$t('Pages.Assistant.Title.Title')"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    :placeholder="$t('Pages.Assistant.Title.Title')"
                    :value="link.title"
                    readonly
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  :label="$t('Pages.Assistant.Title.Url')"
                  label-for="mc-last-name"
                >
                  <b-form-input
                    id="mc-last-name"
                    :placeholder="$t('Pages.Assistant.Title.Url')"
                    :value="link.url"
                    readonly
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              v-if="links.length > 3"
              :title="$t(`Pages.Assistant.Title.add_good_links`)"
              class="border my-1 text-success"
            >
              {{ $t("Pages.Assistant.Title.add_good_links") }}
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="$t(`Pages.Assistant.Title.add_bad_links`)"
              class="border my-1 text-success"
            >
              {{ $t("Pages.Assistant.Title.add_bad_links_desc") }}
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.what_ch_link')"
              class="border my-1"
            >
              {{ $t("Pages.Assistant.Title.what_ch_link_desc") }}
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.tips_best')"
              class="border my-1"
            >
              <ul>
                <li>
                  <h4>
                    {{ $t("Pages.Assistant.Title.links_tricks_title1") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.links_tricks_desc1") }}
                </p>
                <li>
                  <h4>
                    {{ $t("Pages.Assistant.Title.links_tricks_title2") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.links_tricks_desc2") }}
                </p>
                <p
                  class="alert-primary py-1"
                  v-clipboard:copy="sub_confirmation"
                  v-clipboard:success="onCopy"
                >
                  {{ sub_confirmation }}
                </p>
                <li>
                  <h4>
                    {{ $t("Pages.Assistant.Title.links_tricks_title3") }}
                  </h4>
                </li>
                <p class="px-1">
                  {{ $t("Pages.Assistant.Title.links_tricks_desc3") }}
                </p>
              </ul>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.edit_ch_links')"
              class="border my-1"
            >
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/social.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "channel-social",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      links: {},
      sub_confirmation: "",
    };
  },
  created() {
    this.getChannelLinks();
    this.sub_confirmation = `https://www.youtube.com/channel/${this.$route.params.channel_id}?sub_confirmation=1`;
  },
  methods: {
    getChannelLinks() {
      this.$http
        .get(
          `/api/tools/assistant/channel-links?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.links = response.data;
        });
    },
  },
};
</script>

<style></style>
