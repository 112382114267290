<template>
    <b-col cols="12">
        <b-card>
            <b-card-header class="d-flex justify-content-center">
                <h3 >{{ $t('Title') }}: <span>{{ $t('Channel Tabs') }}</span></h3>
            </b-card-header>
            <b-card-body class="d-flex justify-content-center flex-column align-items-center">
                <div class="w-100">
                    <h3 class="pb-1">{{ $t('Your Channel has') + '  '+ playlists.length + '  '+ $t('Public Playlists')}}</h3>
                    <div class="row border text-center d-flex justify-content-center">
                        <div class="col-2 px-1 py-1 ">
                            <b-avatar size="80px" :src="playlist.thumbnail" square />
                            <p class="pt-1 pb-0 mb-0">{{$t('Title')+": "+ playlist.title}}</p>
                            <p class="mb-0">{{$t("Created At")+": "+ playlist.published_at }}</p>
                        </div>
                    </div>
                </div>
                <div class=" w-100">
                    <app-collapse accordion>
                        <app-collapse-item :title="$t('Whats Are Channel Sections?')" class="border my-1">
                            Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy
                            bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake
                            macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake
                            tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping
                            oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie
                            cheesecake liquorice apple pie.
                        </app-collapse-item>
                        <app-collapse-item :title="$t('Tips, Tricks & Best Practices')" class="border my-1">
                            Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy
                            bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake
                            macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake
                            tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping
                            oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie
                            cheesecake liquorice apple pie.
                        </app-collapse-item>
                        <app-collapse-item :title="$t('How To Edit Your Channel Tabs?')" class="border my-1">
                            Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy
                            bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake
                            macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake
                            tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping
                            oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie
                            cheesecake liquorice apple pie.
                        </app-collapse-item>
                    </app-collapse>
                </div>
            </b-card-body>
        </b-card>
    </b-col>
</template>

<script>
import {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
} from "bootstrap-vue";
import MyDate from "@core/components/date/MyDate.vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    name: 'channel-tabs',
    components: {
        BRow,
        BCol,
        BCardText,
        BAvatar,
        BTable,
        BImg,
        BBadge,
        BLink,
        BTooltip,
        BMedia,
        BMediaAside,
        BMediaBody,
        BCardHeader,
        BCardBody,
        BOverlay,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BForm,
        BButton,
        BCard,
        BAlert,
        BFormDatepicker,
        BInputGroup,
        BCardTitle,
        BModal,
        BInputGroupAppend,
        BPaginationNav,
        BCardSubTitle,
        AppCollapse,
        AppCollapseItem,
    },
    data() {
        return {
            MyDate,
            tabs: []
        }
    },
    created() {
        this.getChannelPlaylists();
    },
    methods: {
        getChannelPlaylists() {
            this.$http.get(`/api/tools/assistant/channel-playlists?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`)
                .then((response) => {
                    this.tabs = response.data;
                });
        },
    }
}
</script>

<style>
</style>