<template>
  <b-col md="6" class="analytics-vue">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.ch_sections") }}</h3>
      </b-card-header>
      <!-- <b-card-body class="d-flex justify-content-center align-items-center p-0">
                <div class="special-area w-100 p-2">
                    <div v-for="(section, index) in sections" :key="index" class="border special-radius p-2">
                        <b-media left-align class="special-card text-left">
                            <template #aside>
                                <b-avatar :text="section.position" />
                            </template>
                            <h4 class="media-heading" v-if="section.title">
                                {{ section.title }}
                            </h4>
                            <h4 class="media-heading" v-else>
                                {{ $t('Pages.Assistant.Title.Not Set') }}
                            </h4>
                            <b-card-text>
                                {{ section.type }}
                            </b-card-text>
                        </b-media>
                    </div>
                </div>
            </b-card-body> -->

      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <app-collapse accordion>
            <app-collapse-item
              v-if="sections.length > 0"
              :title="
                $t(
                  `Pages.Assistant.Title.add_good_ch_sections`
                )
              "
              class="border my-1 text-success collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.add_good_ch_sections"
                  )
                }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              v-else
              :title="
                $t(
                  `Pages.Assistant.Title.add_bad_ch_sections`
                )
              "
              class="border my-1 text-danger collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.add_good_ch_sections"
                  )
                }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.y_ch_sections')"
              class="border my-1 collpase-item"
            >
              <hr />
              <div class="special-area">
                <div
                  v-for="(section, index) in sections"
                  :key="index"
                  class="border-btm p-1"
                >
                  <b-media left-align class="special-card text-left">
                    <template #aside>
                      <b-avatar :text="section.position.toString()" />
                    </template>
                    <h4 class="media-heading" v-if="section.title">
                      {{ section.title }}
                    </h4>
                    <h4 class="media-heading" v-else>
                      {{ $t("Pages.Assistant.Title.Not Set") }}
                    </h4>
                    <b-card-text>
                      {{ section.type }}
                    </b-card-text>
                  </b-media>
                </div>
              </div>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.what_ch_sections')"
              class="border my-1 collpase-item"
            >
              <hr />
              <p class="text-color">
                {{
                  $t(
                    "Pages.Assistant.Title.what_ch_sections_desc"
                  )
                }}
              </p>
            </app-collapse-item>
            <app-collapse-item
              :title="$t('Pages.Assistant.Title.tips_best')"
              class="border my-1 collpase-item"
            >
              <hr />
              <ul>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.sections_tricks_title1"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.sections_tricks_desc1"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.sections_tricks_title2"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.sections_tricks_desc2"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.sections_tricks_title3"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.sections_tricks_desc3"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.sections_tricks_title4"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.sections_tricks_desc4"
                    )
                  }}
                </p>
                <li>
                  <feather-icon
                    icon="CheckIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                  <h4>
                    {{
                      $t(
                        "Pages.Assistant.Title.sections_tricks_title5"
                      )
                    }}
                  </h4>
                </li>
                <p class="px-1">
                  {{
                    $t(
                      "Pages.Assistant.Title.sections_tricks_desc5"
                    )
                  }}
                </p>
              </ul>
            </app-collapse-item>
            <app-collapse-item
              :title="
                $t('Pages.Assistant.Title.edit_ch_sections')
              "
              class="border my-1 collpase-item"
            >
              <hr />
              <video width="100%" height="100%" controls>
                <source
                  src="https://api.master.tube/assistant/sections.mp4"
                  type="video/mp4"
                />
              </video>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BEmbed,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "channel-section",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    AppCollapse,
    AppCollapseItem,
    BEmbed,
  },
  data() {
    return {
      sections: {},
    };
  },
  created() {
    this.getChannelSections();
  },
  methods: {
    getChannelSections() {
      this.$http
        .get(
          `/api/tools/assistant/channel-sections?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.sections = response.data;
        });
    },
  },
};
</script>

<style></style>
<style scoped>
.special-card {
  gap: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.special-card .media-aside {
  justify-content: center;
  align-items: center;
  margin: auto;
}

.special-card .media-body {
  text-align: left;
}

.special-card .b-avatar {
  background-color: #82868b !important;
}
.special-area {
  height: 360px;
  overflow-y: scroll;
}
.border-btm {
  border-bottom: 1px solid #3b4253;
  border-radius: 0;
}
.special-area .border-btm:last-child {
  border-bottom: none;
}
</style>
