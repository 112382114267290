var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"analytics-vue",attrs:{"md":"6"}},[_c('b-card',[_c('b-card-header',{staticClass:"d-flex justify-content-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Pages.Assistant.Title.ch_playlists")))])]),_c('b-card-body',{staticClass:"d-flex justify-content-center flex-column align-items-center"},[_c('div',{staticClass:"w-100"},[_c('app-collapse',{attrs:{"accordion":""}},[_c('app-collapse-item',{staticClass:"border my-1 text-danger collpase-item",attrs:{"title":_vm.$t(
                'Pages.Assistant.Title.fewer_than_2'
              )}},[_c('div',{staticClass:"d-flex justify-content-start"},_vm._l((_vm.playlists_less_than_two),function(play){return _c('div',{key:play.id,staticClass:"px-1 py-1"},[_c('b-media',{staticClass:"text-left mb-3",attrs:{"left-align":""},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"80px","src":play.thumbnail,"square":""}})]},proxy:true}],null,true)},[(play.title)?_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(play.title)+" ")]):_vm._e(),_c('b-card-text',[_vm._v(" "+_vm._s(play.published_at)+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.count_vid") + ": " + play.item_count)+" ")])],1)],1)}),0)]),(_vm.average >= 15)?_c('app-collapse-item',{staticClass:"border my-1 text-success collpase-item",attrs:{"title":_vm.$t(
                'Pages.Assistant.Title.average_num'
              )}},[_c('hr'),_c('p',{staticClass:"text-color"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.average_num_desc1") + " "+ _vm.average +" "+ _vm.$t("Pages.Assistant.Title.average_num_desc2"))+" ")])]):_c('app-collapse-item',{staticClass:"border my-1 text-danger collpase-item",attrs:{"title":_vm.$t(
                'Pages.Assistant.Title.average_num'
              )}},[_c('hr'),_c('p',{staticClass:"text-color"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.average_num_desc1") + " "+ _vm.average +" "+ _vm.$t("Pages.Assistant.Title.average_num_desc2"))+" ")])]),(_vm.average_item >= 2)?_c('app-collapse-item',{staticClass:"border my-1 text-success collpase-item",attrs:{"title":_vm.$t(
                'Pages.Assistant.Title.average_num_each'
              )}},[_c('hr'),_c('p',{staticClass:"text-color"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.average_num_each_desc1") + " " + _vm.average_item + " " + _vm.$t("Pages.Assistant.Title.average_num_each_desc2"))+" ")])]):_c('app-collapse-item',{staticClass:"border my-1 text-danger collpase-item",attrs:{"title":_vm.$t(
                'Pages.Assistant.Title.average_num_each'
              )}},[_c('hr'),_c('p',{staticClass:"text-color"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.average_num_each_desc1") + " " + _vm.average_item + " " + _vm.$t("Pages.Assistant.Title.average_num_each_desc2"))+" ")])]),_c('app-collapse-item',{staticClass:"border my-1 collpase-item",attrs:{"title":_vm.$t('Pages.Assistant.Title.y_ch_has') +
              ' ' +
              _vm.playlists.length +
              ' ' +
              _vm.$t('Pages.Assistant.Title.public_playlist')}},[_c('hr'),_c('div',{staticClass:"special-area"},_vm._l((_vm.playlists),function(playlist){return _c('div',{key:playlist.id,staticClass:"special-radius special-card border-btm"},[_c('b-media',{staticClass:"special-card-v2",attrs:{"left-align":""},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"80px","src":playlist.thumbnail,"square":""}})]},proxy:true}],null,true)},[(playlist.title)?_c('h5',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(playlist.title)+" ")]):_vm._e(),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(playlist.published_at)+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.count_vid") + ": " + playlist.item_count)+" ")])],1)],1)}),0)]),_c('app-collapse-item',{staticClass:"border my-1 collpase-item",attrs:{"title":_vm.$t('Pages.Assistant.Title.what_playlist')}},[_c('hr'),_c('p',{staticClass:"text-color"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Assistant.Title.what_playlist_desc"))+" ")])]),_c('app-collapse-item',{staticClass:"border my-1 collpase-item",attrs:{"title":_vm.$t('Pages.Assistant.Title.how_create_playlist')}},[_c('hr'),_c('video',{attrs:{"width":"100%","height":"100%","controls":""}},[_c('source',{attrs:{"src":"https://api.master.tube/assistant/playlists.mp4","type":"video/mp4"}})])])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }